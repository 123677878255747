#frontPage {
    background-color: #282c34;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
}

#container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 10px;
}

/* #container > img {
    width: 300px;
    height: 200px;
    padding-right: 30px;
} */

#imgContainer {
    width: 35%;
    min-width: 250px;
}

#imgContainer > img {
    width: 100%;
}

#textContainer {
    width: 60%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

#introText {
    font-size: 1.5em;
    color: white;
}

#introText > span {
    font-weight: bold;
}

.showing {
    display: flex;
}

.hiding {
    display: none;
}
