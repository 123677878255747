.button {
    padding: 10px 20px;
    border-radius: 5px;
    border-width: 0 0 3px 0;
    transition: all 0.1s;
    border-color: lightgrey;
}

.button:active {
    border-width: 0 0 1px 0;
}

.withMargin {
    margin: 10px;
}

.withoutMargin {
    margin: 0;
}
