.App {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#Main {
    width: 70%;
    background-color: rgb(211, 198, 179);
    border: 10px solid black;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    min-width: 250px;
}

#artworks {
    width: 100%;
    max-width: 40%;
    min-width: 250px;
    margin: 0 auto;
}

#controls {
    flex: 2;
    min-width: 250px;
}

#poetry {
    min-width: 100%;
}
