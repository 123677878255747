:root {
    --control-width: 80%;
}

h4 {
    color: green;
}

.slider {
    -webkit-appearance: none;
    width: var(--control-width);
    height: 15px;
    border-radius: 7px;
    background: white;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
    content: icon;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: black;
    cursor: pointer;
}

#controlContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 90%;
}

#favButtons {
    width: var(--control-width);
    display: flex;
    justify-content: space-between;
}

/*
Farge på bilde
Endre lyd, mood of the music. 
Velge poetry
*/

.sliderContainer {
    width: 100%;
    font-size: 80%;
    text-align: center;
}

p {
    margin: 0;
}

ul {
    margin: 0 auto;
    width: var(--control-width);
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
}
